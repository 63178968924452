// src/components/AboutSection.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
// Replace with your own image
import aboutImage from '../assets/hero-image2.jpg';

/* 
  1. Outer wrapper spans the full width, 
     providing a distinct background color or gradient.
*/
const AboutSectionWrapper = styled.section`
  width: 100%;
  padding: 80px 20px; /* top/bottom, left/right spacing */
  background-color: #f7f9fc; /* a neutral background color */

  display: flex;
  justify-content: center; /* centers the inner container horizontally */
`;

/* 
  2. Inner container (card-like). 
     - White background 
     - Shadow 
     - Rounded corners
     - Enough padding inside for your text & image
*/
const ContainerCard = styled(motion.div)`
  width: 100%;
  max-width: 1200px;             /* limit how wide the card can get */
  background-color: #fff;        /* white background */
  border-radius: 12px;           /* smooth corners */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
  padding: 40px;
  display: flex;
  gap: 40px;

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
`;

/* 
  3. One column for text, one for the image
  We'll keep them side-by-side on desktop,
  stacked on mobile screens.
*/

const TextColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/* 
  4. Basic styling for the image inside the container
*/

const AboutImage = styled.img`
  width: 100%;
  max-width: 500px; /* ensure it's not too large */
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);

  @media (max-width: 768px) {
    max-width: 400px;
  }
`;

/* 
  5. Typography
*/

const Title = styled.h2`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 24px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Text = styled.p`
  font-size: 1.125rem; /* ~18px */
  line-height: 1.6;
  color: #7f8c8d;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const AboutSection = () => {
  return (
    <AboutSectionWrapper id="about">
      <ContainerCard
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        {/* Text Column */}
        <TextColumn>
          <Title>About Us</Title>
          <Text>
            We are dedicated to improving lives through compassionate action and impactful
            programs. Our organization works globally to address critical issues and
            foster sustainable change.
          </Text>
          <Text>
            Join us in our mission to make the world a better place for all.
          </Text>
        </TextColumn>

        {/* Image Column */}
        <ImageColumn>
          <AboutImage src={aboutImage} alt="About" />
        </ImageColumn>
      </ContainerCard>
    </AboutSectionWrapper>
  );
};

export default AboutSection;
