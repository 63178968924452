// src/components/HeroSection.js
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

// Example image imports; replace with your own images
import heroImage1 from '../assets/hero-image2.jpg';
import heroImage2 from '../assets/hero-image2.jpg';
import heroImage3 from '../assets/hero-image2.jpg';

const slides = [
  {
    id: 1,
    imgSrc: heroImage1,
    title: 'Making a Difference Together',
    subtitle: 'Join us in our mission to create a better world for everyone.',
    ctaText: 'Learn More',
    ctaLink: '#about',
  },
  {
    id: 2,
    imgSrc: heroImage2,
    title: 'Empowering Communities',
    subtitle: 'We believe in grassroots change and sustainable progress.',
    ctaText: 'Get Involved',
    ctaLink: '#contact',
  },
  {
    id: 3,
    imgSrc: heroImage3,
    title: 'Building a Brighter Future',
    subtitle: 'Every contribution makes a lasting difference.',
    ctaText: 'Donate Now',
    ctaLink: '#donate',
  },
];

// Container for the entire hero/slider section
const HeroContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: 60px; /* space for header */
  overflow: hidden;
`;

const SlideBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ bg }) => `url(${bg}) center/cover no-repeat`};
`;

// Dark overlay across each slide for contrast
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.6);
`;

// Content wraps the text/CTA for each slide
const Content = styled(motion.div)`
  position: relative;
  color: #ecf0f1;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto; /* centers the content */
  top: 50%;
  transform: translateY(-50%);
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const CTAButton = styled(motion.a)`
  padding: 15px 30px;
  background-color: #e74c3c;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;

// Navigation dots (indicators) container
const DotsContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
`;

// Larger pagination dot
const Dot = styled.span`
  width: 20px;     /* Increased from 12px to 20px */
  height: 20px;    /* Increased from 12px to 20px */
  border-radius: 50%;
  background-color: #bdc3c7;
  cursor: pointer;
  transition: background-color 0.3s ease;

  ${({ active }) =>
    active &&
    css`
      background-color: #e74c3c;
    `}
`;

const HeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Automatic slide transition every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000); // 5 seconds

    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, []);

  // Handler to go to a specific slide by index
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <HeroContainer id="home">
      <AnimatePresence mode="popLayout">
        {slides.map((slide, index) =>
          index === currentSlide ? (
            <SlideBackground
              key={slide.id}
              bg={slide.imgSrc}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <Overlay />
              <Content
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -30 }}
                transition={{ duration: 1 }}
              >
                <Title>{slide.title}</Title>
                <Subtitle>{slide.subtitle}</Subtitle>
                <CTAButton
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  href={slide.ctaLink}
                >
                  {slide.ctaText}
                </CTAButton>
              </Content>
            </SlideBackground>
          ) : null
        )}
      </AnimatePresence>

      {/* Pagination Dots */}
      <DotsContainer>
        {slides.map((_, i) => (
          <Dot key={i} active={i === currentSlide} onClick={() => goToSlide(i)} />
        ))}
      </DotsContainer>
    </HeroContainer>
  );
};

export default HeroSection;
