// src/components/Footer.js
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaEnvelope,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from 'react-icons/fa';

// Existing fade-up animation for the footer
const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

/** 
 * 1) Multi-gradient SHIFT keyframe:
 *    This cycles the background gradient horizontally.
 */
const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

/**
 * 2) Pulse "blink" keyframe:
 *    The text transitions between full opacity and partial opacity,
 *    giving a more interesting "blink" effect than fully disappearing.
 */
const pulseBlink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
`;

/** 
 * 3) BlinkingGradientName:
 *    - Multi-color background gradient
 *    - Use background-clip + color: transparent for "gradient text"
 *    - Combine gradientShift & pulseBlink animations
 */
const BlinkingGradientName = styled.strong`
  display: inline-block;
  background: linear-gradient(
    270deg, 
    #ff416c, 
    #ff4b2b, 
    #ff7e5f, 
    #feb47b
  );
  background-size: 600% 600%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: 
    ${gradientShift} 6s ease infinite,    /* shift the gradient across the text */
    ${pulseBlink} 2s ease infinite;       /* pulse the opacity */
`;

// Footer Container
const FooterContainer = styled.footer`
  background: linear-gradient(135deg, #1b1b1b 0%, #2c3e50 100%);
  color: #ecf0f1;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  animation: ${fadeUp} 1s ease-out forwards;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const FooterSection = styled.div`
  flex: 1 1 250px;
  margin: 10px;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    margin: 8px 0;
  }
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 12px;
  color: #ecf0f1;
`;

const Description = styled.p`
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 10px;
`;

const QuickLinks = styled.ul`
  list-style: none;
  padding: 0;
`;

const QuickLink = styled.li`
  margin-bottom: 8px;
`;

const FooterLink = styled.a`
  color: #ecf0f1;
  text-decoration: none;
  font-size: 13px;
  transition: color 0.3s ease;

  &:hover {
    color: #e74c3c;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const ContactText = styled.span`
  margin-left: 8px;
  font-size: 13px;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 8px;
`;

const SocialLink = styled.a`
  color: #ecf0f1;
  font-size: 16px;
  transition: color 0.3s ease;

  &:hover {
    color: #e74c3c;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const InputField = styled.input`
  padding: 8px 12px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  font-size: 13px;

  &:focus {
    outline: none;
    box-shadow: 0 0 4px #e74c3c;
  }
`;

const TextArea = styled.textarea`
  padding: 8px 12px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  font-size: 13px;
  resize: vertical;
  min-height: 80px;

  &:focus {
    outline: none;
    box-shadow: 0 0 4px #e74c3c;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 18px;
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-start;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px #ffffff;
  }
`;

const SuccessMessage = styled.p`
  color: #2ecc71;
  font-size: 13px;
  margin-top: 5px;
`;

const FooterBottom = styled.div`
  width: 100%;
  border-top: 1px solid #34495e;
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
`;

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [success, setSuccess] = useState(false);

  const { name, email, message } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (success) setSuccess(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name.trim() || !email.trim() || !message.trim()) {
      alert('Please fill in all fields.');
      return;
    }
    console.log('Form Submitted:', formData);
    setFormData({ name: '', email: '', message: '' });
    setSuccess(true);
  };

  return (
    <FooterContainer id="contact">
      {/* About Us Section */}
      <FooterSection>
        <SectionTitle>About Us</SectionTitle>
        <Description>
          We are dedicated to making a positive impact in our community through various
          initiatives and programs. Join us in our mission to create a better future for all.
        </Description>
      </FooterSection>

      {/* Quick Links Section */}
      <FooterSection>
        <SectionTitle>Quick Links</SectionTitle>
        <QuickLinks>
          <QuickLink>
            <FooterLink href="#home">Home</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#about">About Us</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#activities">Activities</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#members">Members</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#events">Events</FooterLink>
          </QuickLink>
          <QuickLink>
            <FooterLink href="#contact">Contact</FooterLink>
          </QuickLink>
        </QuickLinks>
      </FooterSection>

      {/* Contact Information Section */}
      <FooterSection>
        <SectionTitle>Contact Us</SectionTitle>
        <ContactInfo>
          <FaMapMarkerAlt />
          <ContactText>1234 Street Name, City, State, 56789</ContactText>
        </ContactInfo>
        <ContactInfo>
          <FaPhoneAlt />
          <ContactText>(123) 456-7890</ContactText>
        </ContactInfo>
        <ContactInfo>
          <FaEnvelope />
          <ContactText>info@yourorganization.org</ContactText>
        </ContactInfo>
        <SocialIcons>
          <SocialLink
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebookF />
          </SocialLink>
          <SocialLink
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <FaTwitter />
          </SocialLink>
          <SocialLink
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram />
          </SocialLink>
          <SocialLink
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedinIn />
          </SocialLink>
        </SocialIcons>
      </FooterSection>

      {/* Contact Form Section */}
      <FooterSection>
        <SectionTitle>Send Us a Message</SectionTitle>
        <ContactForm onSubmit={handleSubmit}>
          <InputField
            type="text"
            name="name"
            placeholder="Your Name"
            value={name}
            onChange={handleChange}
            required
          />
          <InputField
            type="email"
            name="email"
            placeholder="Your Email"
            value={email}
            onChange={handleChange}
            required
          />
          <TextArea
            name="message"
            placeholder="Your Message"
            value={message}
            onChange={handleChange}
            required
          />
          <SubmitButton type="submit">Send Message</SubmitButton>
          {success && <SuccessMessage>Your message has been sent successfully!</SuccessMessage>}
        </ContactForm>
      </FooterSection>

      {/* Footer Bottom */}
      <FooterBottom>
        &copy; {new Date().getFullYear()} Your Organization. All rights reserved.
        <br />
        Developed By <BlinkingGradientName>Md Mahmudul Hasan (Software Engineer)</BlinkingGradientName>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
