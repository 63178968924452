// src/components/Header.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';

const HeaderContainer = styled(motion.header)`
  width: 100%;
  height: 60px; /* Fix the header height */
  padding: 0 30px; /* Reduced horizontal padding */
  display: flex;
  justify-content: space-between;
  align-items: center;
   background: linear-gradient(135deg, #1b1b1b 0%, #2c3e50 100%);
  position: fixed;
  
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(255, 204, 0, 0.9);

  /* Responsive adjustments */
  @media (max-width: 768px) {
    padding: 0 20px;
    height: 50px; /* Optionally reduce further on mobile */
  }
`;

const Logo = styled.h1`
  font-size: 20px;   /* Slightly smaller font for a compact header */
  color: #ffffff;
  margin: 0;         /* Remove any default margins */

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 600px) {
    display: block;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;

  /* On smaller screens, convert to a slide-in menu */
  @media (max-width: 600px) {
    position: absolute;
    top: 50px; /* Shift down to match the reduced header height */
    right: 0;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 8px;
    gap: 10px;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;
  }
`;

const NavLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: #e74c3c;
    transition: width 0.3s;
    position: absolute;
    bottom: -5px;
    left: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Logo>Jashore Foundation</Logo>
      <HamburgerMenu onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </HamburgerMenu>
      <Nav isOpen={isOpen}>
        <NavLink href="#home" onClick={() => setIsOpen(false)}>Home</NavLink>
        <NavLink href="#about" onClick={() => setIsOpen(false)}>About</NavLink>
        <NavLink href="#activities" onClick={() => setIsOpen(false)}>Activities</NavLink>
        <NavLink href="#members" onClick={() => setIsOpen(false)}>Members</NavLink>
        <NavLink href="#events" onClick={() => setIsOpen(false)}>Events</NavLink>
        <NavLink href="#contact" onClick={() => setIsOpen(false)}>Contact</NavLink>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
